import "./SiteNavigationElement.less";

class SiteNavigationElement extends HTMLElement {
    public static tag = "site-navigation";
    connectedCallback() { 
        if (!this.hasAttribute("rendered"))
            this.innerHTML = this.view();

        if (this.hasAttribute("keep-small"))
            return;

        document.addEventListener("scroll", this.scrollHandler, { passive: true });
        document.addEventListener("pointerup", this.scrollHandler, { passive: true });
    }

    disconnectedCallback() { 
        document.removeEventListener("scroll", this.scrollHandler);
        document.removeEventListener("pointerup", this.scrollHandler);
    }

    private scrollHandler = () => { 
        const scrollPos = Math.max(document.documentElement.scrollTop, window.scrollY);
        this.toggleAttribute("small", scrollPos > 90);
    }

    private view = () => `
        <nav>
            <a href="/#rooms">
                Rooms
            </a>
            <a href="/#features">
                Features
            </a>
            <a href="/" class="logo">
                <span name=big>${require("../Homepage/Images/zebra-escapes-logo-white.svg")}</span>
                <span name=small>${require("../Metadata/Images/favicon.svg")}</span>
            </a>
            <a href="/#notify-me">
                Notify me
            </a>
            <a href="/#contact-us">
                Contact
            </a>

          
        </nav>
        
        ${this.hasAttribute("share") ? `
            <share-content image-url="${this.getAttribute("image-url") || ""}"></share-content>
        ` : ""}
    `;
}

customElements.define(SiteNavigationElement.tag, SiteNavigationElement);

