import "./logConfig";
import "./Features";
import { LogStore } from "Feature/Logging/LogStore";


// a test
LogStore.instance.logInformation("Opened page", {
    page: window.location.pathname,
    title: document.title,
    referrer: document.referrer,
    eventKind: "page-hit"
});