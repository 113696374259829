import { LogStore } from "Feature/Logging/LogStore";
import "./ShareContentElement.less";

class ShareContentElement extends HTMLElement { 
    static tag = "share-content";
    private _options: HTMLElement;
    private _facebookButton: HTMLButtonElement;
    private _blueskyButton: HTMLButtonElement;
    private _copyLinkButton: HTMLButtonElement;
    private _downloadButton: HTMLButtonElement;
    private _nativeButton: HTMLButtonElement;

    connectedCallback() { 
        this.innerHTML = this.view();
        this._options = this.querySelector(".options");
        const button = this.querySelector("button");

        this._nativeButton = this.querySelector("button[name=native]");
        this._nativeButton?.addEventListener("click", this.shareViaNative);

        this._facebookButton = this.querySelector("button[name=facebook]");
        this._facebookButton.addEventListener("click", this.shareViaFacebook);

        this._blueskyButton = this.querySelector("button[name=bluesky]");
        this._blueskyButton.addEventListener("click", this.shareViaBluesky);

        this._copyLinkButton = this.querySelector("button[name=copy]");
        this._copyLinkButton.addEventListener("click", this.copyLink);

        this._downloadButton = this.querySelector("button[name=download]");
        this._downloadButton?.addEventListener("click", this.downloadImage);

        button.addEventListener("click", this.toggleOptions);

        document.addEventListener("pointerdown", this.hideOptions);

    }

    private shareViaNative = () => { 
        console.log("Sharing via native");
        try {
            navigator.share({
                title: document.title,
                text: document.title,
                url: this.url,
            });
            this.logShare("native");
        } catch (error) {
            console.error("Error sharing via native:", error);
        }
    }

    private canShareNative = () => { 
        if (!navigator.canShare)
            return false;

        return navigator.canShare({
            title: document.title,
            text: document.title,
            url: this.url,
        });
    }

    private get url() { 
        return window.location.href;
    }

    private shareViaFacebook = () => { 
        const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`;
        window.open(fbUrl, "_blank");
        this.logShare("facebook");
        this.hide();
    }

    private shareViaBluesky = () => { 
        const fbUrl = `https://bsky.app/intent/compose?text=${encodeURIComponent(this.url)}`;
        window.open(fbUrl, "_blank");
        this.logShare("bluesky");
        this.hide();
    }

    private copyLink = () => {
        navigator.clipboard.writeText(this.url);
        this.logShare("copy-link");
        this.hide();
    };

    private downloadImage = async () => {
        const imageUrl = this.getAttribute("image-url");
        if (!imageUrl) return;
    
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
    
            // Extract the original extension from the URL
            const urlParts = imageUrl.split(".");
            const extension = urlParts.length > 1 ? urlParts.pop()?.split("?")[0] : "jpg"; // Default to jpg if no extension found
            const filename = `postcard.${extension}`;
    
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            this.logShare("download-image");
            this.hide();
        } catch (error) {
            console.error("Error downloading image:", error);
        }
    };

    private logShare = (shareType: string) => { 
        LogStore.instance.logInformation("Shared page", {
            page: window.location.pathname,
            title: document.title,
            referrer: document.referrer,
            eventKind: "share",
            shareType: shareType
        });   
    }
    

    hide = () => { 
        this._options.removeAttribute("show");
    }

    hideOptions = (event: PointerEvent) => {
        // check if click is within this element
        if (!(event.target instanceof Element))
            return;

        const target = event.target as Element;

        if (this.contains(target))
            return;

        this._options.removeAttribute("show");
    }

    private toggleOptions = () => { 
        this._options.toggleAttribute("show");
        this.logShare("start-share");
    }

    private view = () => `
        <button type=button>${require("./Images/share.svg")} Share</button>
        <div class=options>
            ${this.canShareNative ? `
                <button type=button class=device name=native>
                    <div>${require("./Images/device.svg")}</div><div>Share via Device</div>
                </button>
            ` : ""}
            
             <button type=button name=facebook>
                <div>${require("./Images/facebook.svg")}</div><div>Share to Facebook</div>
            </button>
             <button type=button name=bluesky>
                <div>${require("./Images/bluesky.svg")}</div><div>Share to Bluesky</div>
                
            </button>
            ${this.getAttribute("image-url") !== "" ? `
                <button type=button name=download>
                    <div>${require("./Images/download.svg")}</div><div>Download image</div>
                </button>
            `: ""}
        
            <button type=button name=copy>
                <div>${require("./Images/link.svg")}</div><div>Copy link</div>
            </button>
        </div>
    `;
}

customElements.define(ShareContentElement.tag, ShareContentElement);